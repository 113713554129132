module.exports = {
    'title' : 'Edit Profile',
    'label_salutation': 'Title',
    'label_firstname': 'First Name',
    'label_lastname': 'Last Name',
    'label_email': 'Email',
    'label_gender': 'Gender',
    'gender_type_male': 'MALE',
    'gender_type_female': 'FEMALE',
    'label_birthdate': 'Birth Date',
    'label_day': 'Day',
    'label_month': 'Month',
    'label_year': 'Year',
    'label_mobile_phone': 'Mobile Phone',
    'label_slectbox': 'Please select',
    'label_nationality': 'Nationality',
    'label_country_of_residence': 'Country Of Residence',
    'label_language': 'Preferred Language',
    'label_address': 'Mailing Address',
    'terms_description': 'I would like to receive updates and information on new services and promotions',
    'monthly_sub_description': 'I would like to receive Monthly Account Summary',
    'label_btn_submit': 'Save My Changes'
}